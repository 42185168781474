import React from "react";
import logo from "./Alcadeth.png";
import "./App.css";
import Item from "./components/atoms/Item";
import BuildContainer from "./components/molecules/BuildContainer";

function App() {
  return (
    <div className="App" style={{ width: "100vw", height: "100vh" }}>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <BuildContainer
          items={[
            {
              isMythic: true,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/c/c2/Eclipse_item.png",
            },
            {
              isMythic: false,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/8/8b/Bloodthirster_item.png",
            },
            {
              isMythic: false,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/8/86/Frozen_Heart_item_HD.png",
            },
            {
              isMythic: false,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/c/c2/Eclipse_item.png",
            },
            {
              isMythic: false,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/8/8b/Bloodthirster_item.png",
            },
            {
              isMythic: false,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/8/86/Frozen_Heart_item_HD.png",
            },
            {
              isMythic: false,
              imageUrl:
                "https://static.wikia.nocookie.net/leagueoflegends/images/c/c2/Eclipse_item.png",
            },
          ]}
          title="Core"
        />
      </header> */}
      <iframe
        src="https://docs.google.com/document/d/192r9XBnysQdLWUgU86GHOOBTjb2NdCUlvmUS87H0c-A/edit#heading=h.wuejy56gfrmk"
        title="Alcadeth"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}

export default App;
